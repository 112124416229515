import React, { useEffect, useState } from 'react';
// import './UI/Slides/Slides.css'; 
import { Slides } from './UI/Slides';
import { Users } from './Lab/consts';



function App() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [timer, srtTimer] = useState<number>(10000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % Users.length);
      timer!==10000 && srtTimer(10000)
    }, timer);
  
    return () => clearInterval(intervalId);
  
  }, [timer]);




  return (
    <div className="App h-screen w-screen overflow-hidden transition-all relative">



      {Users.map((slide, index) => (
        <Slides 
          key={index}
          index={index}
          bg={slide.bg}
          icon={slide.image}
          address={slide.address}
          text={slide.text}
          urls={slide.url}
          name={slide.name}
          active={index === activeIndex ? 1 : 0}
        />
      ))}
      <div className='absolute m-auto z-30 bottom-0 top-0  right-1 '>
        <div className='flex flex-col gap-1  py-2 rounded-b-lg  justify-start items-center bg-opacity-50 backdrop-blur-sm  shadow-lg'>
            {Users.map((slide, index) => (
              <div key={index} className='cursor-pointer    rounded-full flex justify-center items-center overflow-hidden size-12' onClick={()=>{setActiveIndex(index);srtTimer(30000);}}>
              {slide.image &&  <img src={slide.image} className={` ${activeIndex===index?"size-12 border-4 border-[#D39250] border-opacity-6":"size-10"} rounded-full hover:size-12 `} alt="" />}
              </div>
            ))}
          </div>
      </div>
    </div>
  );
}

export default App;
