import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';

interface IProp{
    index:number
    bg:string
    icon:string|null
    address:string[]|null
    name:string
    text:string[]
    urls:string[]|null
    active:number
}

export function Slides(prop:IProp) {
    let top1 = "3%"
    let top2 = "27%"
    let top3 = "70%"

    let left1 = "3%"
    let right3 = "10%"
    let left = "-100%"
    let opacity = 0
    let zIndex = 0
  

    const [left2,setLeft2] = useState("8%")
    const [w,setW] = useState("77%")

    const {bg,icon,text,urls, active,name, index,address} =prop

        if (active) {
             left = "0px"
            opacity = 1
            for (let index = 0; index < 1000; index++) {
               setTimeout(()=>{opacity = opacity + 0.1},500) 

            }
             zIndex = 1
        }else{
            left = "-100%"
              opacity = 0
            zIndex = 0
        }
        function scrinPos() {
                if (window.matchMedia("(orientation: landscape)").matches) {
                  // Телефон в горизонтальной ориентации
                  setLeft2("8%")
                   setW("77%")
               
                } else {
                  // Телефон в вертикальной ориентации
                  setLeft2("25%")
                  setW("55%")
                 
                }
        }
        useEffect(()=>{
            setLeft2("8%")
            setW("77%")
        
        },[])
        window.addEventListener('orientationchange', () => {
            scrinPos()
          });

        function userIcons(link:string){
           
        if(link.includes("vk")){
            return  <img src={`imagis/socialNets/vk.svg`} width={15} className='filter opacity-50 hover:opacity-100' alt="страница коллекционера в vk"/>
        }else if(link.includes("instagram")){
            return  <img src={`imagis/socialNets/instagram.svg`} width={15} className='filter opacity-50 hover:opacity-100' alt="страница коллекционера в instagram"/>
        }else if(link.includes("morpribor")){
            
            return  <img src={`imagis/socialNets/morpribor.jpg`} width={15} className='filter opacity-50 hover:opacity-100' alt=" страница коллекционера - morpribor.ru"/>
        }
        else if(link.includes("avito")){
            
            return  <img src={`imagis/socialNets/avito.svg`} width={15} className='filter opacity-50 hover:opacity-100' alt="страница коллекционера на avito.svg"/>
        }
        else if(link.includes("t.me")){
            
            return  <img src={`imagis/socialNets/tg.svg`} width={15} className='filter opacity-50 hover:opacity-100' alt="страница коллекционера на avito.svg"/>
        }
           return  link
          //  return <img src=`\imagis\socialNets\`
        }

      

    return(
      
         <div key={index} className="h-full w-full overflow-hidden absolute top-0" style={{ backgroundImage: `url(${bg})`, backgroundPosition:"center", backgroundSize: 'cover', imageRendering:"-webkit-optimize-contrast", backgroundRepeat:"no-repeat", left:`${left}`,opacity:`${opacity}`, zIndex:`${zIndex}` }}>
              {icon &&  <div className="size-44 overflow-hidden rounded-full shadow-2xl hover:shadow-slate-900 absolute cursor-pointer" style={{top:`${top1}`,left:`${left1}`}}> 
                <img src={icon} className='size-44 object-cover' alt="" />  
                </div>}
                <div className= {` h-[39%] backdrop-blur-sm hover:shadow-xl bg-white bg-opacity-70 rounded-xl absolute px-5 py-2 overflow-hidden overflow-y-auto lg:font-semibold lg:text-lg gap-2`}  style={{top:`${top2}`,left:`${left2}`,width:`${w}`}}>
                <div className='font-bold text-lg pb-1 flex flex-row flex-wrap gap-3 items-center  '>
                    <h3 className='flex items-center'>{name}</h3>
                    <div className='flex flex-row gap-2 items-start'>  
                        {urls &&urls.map((string:string, index:number)=><p key={index}  className='pb-1'><Link to={string} className='text-lg' title="персональная страница коллекционера">{userIcons(string)}</Link></p>)}
                    </div>
                </div>
                    {text && text.map((string:string, index:number)=>
                        <p key={index} className='pb-1'>{string}</p>
                    )} 

                    
                </div>
                {urls &&<Link to={urls[0]} > <div  style={{top:`${top3}`,right:`${right3}`, position:"absolute"}} className='bg-white p-5 rounded-lg hover:shadow-2xl' ><QRCode  className='cursor-pointer' value={urls[0]} title={"на страницу коллекционера - "+ urls[0]}  /></div></Link>}
                {address &&  <div  style={{top:`${top3}`,right:`${right3}`, position:"absolute"}} className='bg-white p-5 rounded-lg hover:shadow-2xl' >
                    {address.map((string:string, index:number)=>
                        <p key={index} className='pb-1 text-xl'>{string}</p>
                    )}
                    
                    </div> }
        </div>
  

    )
}