import { Malinacraftfactory } from "./malinacraftfactory";

export const Users = [
  // {
  //     name:"Бронзовый век",
  //     bg:"imagis/wLRnJ3rsPi01.jpg",
  //     text:["На выставке представлены:", 
  //     "Более 100 наручных часов из бронзы и латуни", 
  //     "Более 500 ремешков из 25 видов кожи", 
  //     "Более 300 авторских пряжек из бронзы и латуни",
  //   ],
  //   address:["Москва,"," Русаковская ул., д. 1, этаж 3 ", 
  //   "с 10:00 до 21:00 без выходных ", ],
  //     image:"userImgs/V/icon.jpg",
  //     url:null,
  //     left:100,
  //     active:0,
  //     shop:null
  //   },
    {
      name:"Виктор Батырев",
      text:["Коллекционер бронзовых часов, ремешков и пряжек."],
      bg:"userImgs/Viktor/bg.jpg",
      image:"userImgs/Viktor/icon.jpg",
      url:["https://www.instagram.com/direktor_mmm?igsh=bmY5bzFveXMzbW4y"],
      address:null,
      left:0,
      active:1,
      shop:null
    }, {
      name:"Максим Малиновский",
      bg:"userImgs/Maks/bg.jpg",
      image:"userImgs/Maks/icon.jpg",
      text:["Коллекционер бронзовых часов, изготавливает ремешки для часов.",
      "Специализируется на ремешках из бейсбольной перчатки и из экзотической кожи.",
      "Помимо этого, делает различные аксессуары: браслеты, кардхолдеры, очешники, чехлы и другую галантерею."],
      url:["https://t.me/malinacraftfactory","https://vk.com/malinacraftfactory","https://www.instagram.com/malinacraftfactory?igsh=MTYzZnNjaDgyMTJ3ag==", "https://www.avito.ru/user/1fee7473f9a77f394e9f348219804e16/profile?src=sharing"],
      address:null,
      left:-100,
      active:0,
      shop:Malinacraftfactory
    },{
      name:"Александр Ларин",
      bg:"userImgs/Aleksandr/bg.jpg",
      text:["Коллекционер бронзовых часов.","Автор дизайна часов \"Морприбор\"."],
      image:"userImgs/Aleksandr/icon1.jpg",
      url:["https://morpribor.ru/"],
      address:null,
      left:-100,
      active:0,
      shop:null
    },{
      name:"Игорь Мелех",
      bg:"userImgs/Igor/bg.jpg",
      image:"userImgs/Igor/icon.jpg",
      text:["Производитель пряжек и металлических аксессуаров."],
      address:null,
      url:null,
      left:-100,
      active:0,
      shop:null
    },{
      name:"Антон Подгурский",
      bg:"imagis/O3V5F_qN69M.jpg",
      text:["Производитель ремешков и аксессуаров из кожи."],
      image:"userImgs/Anton/icon1.jpg",
      url:["https://vk.com/kleaks"],
      address:null,
      left:-100,
      active:0,
      shop:null
    },{
      name:"Александр Жиленков",
      bg:"userImgs/AleksandrJ/bg.jpg",
      text:["Автор бронзовых, тематических пряжек с разнообразными сюжетами.",
      "Изготавливает ремешки, как в классических, так и в уникальных вариантах. Использует широчайший ассортимент кожи, от КРС и до экзотики.",
      "Коллекционер часов из бронзы и латуни."],
      image:"userImgs/AleksandrJ/icon.jpg",
      address:null,
      url:["https://www.instagram.com/invites/contact/?i=p0w9t5kk1vf2&utm_content=lrvykoq","https://www.avito.ru/user/5ee0ab839c25c74eae0038df87120b64/profile?src=sharing"],
      left:-100,
      active:0,
      shop:null
    }

  ,
    // ,{
    //   name:"Евгений Ролин",
    //   bg:"imagis/wLRnJ3rsPi01.jpg",
    //   text:["Автор и производитель платков."],
    //   image:"userImgs/i1.png",
    //   url:null,
    //   left:100,
    //   active:0,
    //   shop:null
    // },
  
  ]


 













